import { template as template_675b028253cd4f5dab028afb801fa3d5 } from "@ember/template-compiler";
const WelcomeHeader = template_675b028253cd4f5dab028afb801fa3d5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
