import { template as template_023b2c44663248e9b927bb94ecea4568 } from "@ember/template-compiler";
const FKLabel = template_023b2c44663248e9b927bb94ecea4568(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
